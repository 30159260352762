<template>
  <div class="card card-floating">
    <div class="top-image">
      <div class="gambar-kartu">
        <img src="assets/images/v284_1255.png" class="image-card" alt="illustration" />
      </div>
      <div class="text-kartu">
        <p class="card-title paragraf3">Mendorong Terciptanya Pengelolaan ORMAS yang Profesional, Transparan dan
          Akuntabel
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardFloat3"
};
</script>

<style scoped>














@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .top-image {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: unset !important;
      height: auto !important;
      border-radius: unset !important;
      background: unset !important;
      box-shadow: unset !important;
      align-content: center;
        align-items: center;
    }
  
    .image-card {
    width: 62.2%;
      height: auto;
      margin: 18.9%;
    }
  
        .gambar-kartu {
          position: unset;
          width: 17.69%;
          height: 100%;
          border-radius: 25%;
          background: #FFFFFF;
          box-shadow: 0 4px 30px rgb(0 0 0 / 25%);
        }
  
    .text-kartu {
      width: 82.31%;
    }
  
    .paragraf3 {
    font-size: 120%;
      line-height: 145%;
      width: unset;
      font-weight: bold;
      margin-left: unset;
      margin-top: unset;
      color: #FFFFFF;
      text-align: left;
      margin: 5%;
      height: auto !important;
      text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    }
}
@media screen and (min-device-width: 641px) and (min-width: 641px) and (max-device-width: 1053px) and (max-width: 1053px) and (-webkit-min-device-pixel-ratio: 2) {
  .top-image {
      top: unset !important;
        width: 53.888% !important;
        height: auto !important;
        left: unset !important;
      background: unset !important;
      box-shadow: unset !important;
        border-radius: unset !important;display: flex;
          flex-direction: column;
          position: unset !important;
          margin-top: -30% !important;
          margin-left: 23%;
    }
  
        .image-card {
          width: 80%;
          height: auto;
          margin: 10%;
        }
    
        .gambar-kartu {
          width: 100% !important;
          height: auto !important;
          border-radius: 15%;
          background: #FFFFFF;
          box-shadow: 0 4px 30px rgb(0 0 0 / 25%);
        }
  
        .text-kartu {
          width: 140%;
          margin-left: -20%;
          padding-top: 15%;
        }
    
        .paragraf3 {
          font-size: 100%;
          line-height: 135%;
          width: unset;
          font-weight: bold;
          margin-left: unset;
          margin-top: unset;
          color: #FFFFFF;
          text-align: center;
          margin: 0px !important;
          height: auto !important;
          text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        }
}
</style>
