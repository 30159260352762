<template>
  <nav class="navbar-expand-lg white-font sub_kepala">
    <!-- <div class="container m-0"> -->
    <b-row class="subsub" style="margin-right: 0 !important;margin-left: 0 !important">
      <b-col cols="6" style="padding:0;">
        <b-row class="header_menu">
          <div class="gambar">
            <img src="/assets/images/logo1.svg" alt="" />
          </div>
          <a @click="tombolMenu()" class="icon" id="tombolMenu">
            <i class="fa fa-bars" style="display: flex;
    padding: 25%;
    color: rgb(67, 69, 71);
    margin-top: 74%;
    border-radius: 25%;
    border: solid rgb(67, 69, 71);
    align-content: center;
    justify-content: center;
    align-items: center;"></i>
          </a>
        </b-row>
      </b-col>
      <b-col class="daftar_menu" id="daftar_menu_id" ref="daftar_menu_id" cols="6" style="padding:0;"
        :style="{ display: `${this.nilai}` }">
        <!-- <b-col v-if="this.nilai == 'block'" class="text-right d-flex align-items-center daftar_menu" id="daftar_menu_id" ref="daftar_menu_id" cols="6" style="padding:0;display: block !important;"> -->
        <!-- <b-col class="text-right d-flex align-items-center daftar_menu" id="daftar_menu_id" ref="daftar_menu_id"
          cols="6" style="padding:0;" :style="{display: ['none !important','block !important']}"> -->

        <div class="" id="navbarNav">
          <ul class="lp_menu">
            <li class="isi_menu">
              <router-link class="nav-link badge bg-navbar" to="/">Beranda</router-link>
            </li>
            <li class="isi_menu" style="margin-left: 3%;">
              <router-link class="nav-link badge bg-navbar" to="/aktivasi">Aktivasi</router-link>
            </li>
            <li class="isi_menu" style="margin-left: 3%;">
              <transition name="fade-in-up">
                <router-view />
              </transition>
              <router-link v-if="!isToken" class="nav-link badge bg-navbar" to="/login">Login</router-link>
              <b-button @click="checkToken()" v-if="isToken" class="nav-link badge bg-navbar"
                style="background:white;color:rgba(0, 0, 0, 0.38);">Masuk</b-button>
            </li>
          </ul>
        </div>

      </b-col>
    </b-row>
    <!-- </div> -->
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data(){
    return {
      isToken: false,
      nilai: '',
      // tinggi: '150px',
      lebar: document.body.clientWidth,
    }
  },
  mounted(){
    this.$nextTick(() => {
    if(localStorage.getItem('token')) this.isToken = true;
    if(this.lebar > "640px") this.nilai = 'block !important';
    });
  },
  methods:{
    checkToken(){
      this.$store
        .dispatch("getProfile").then(()=>{
          this.$router.push('/dashboard')
        }).catch(()=>{
          localStorage.removeItem('token')
          location.reload()
        })
    },
    tombolMenu() {
      if (this.nilai === "block !important") {
        this.nilai = 'none !important';
      } else {
        this.nilai = 'block !important';        // this.tinggi ='205px';
      }
      return;
    },
  }
};
</script>

<style>


a.nav-link.badge.bg-navbar.router-link-exact-active.router-link-active{
  color:white !important;
}
.sub_kepala{
  margin-top: 0 !important; 
  padding-top: 32px !important;
  /* height: 100% !important; */
}
.header_menu{
margin-right: 0px !important;
    margin-left: 0px !important;
}
.gambar{
  width: auto;
    height: auto;
    
}
.icon{
  display: none;
  margin-left: 0px;
  
}
.daftar_menu {
 margin-top: 3%;
text-align: right !important;
}
.lp_menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-end;
}
.isi_menu{
  border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.38);
    border-radius: 10px;
    text-align: center;
}

@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 798px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 2) {
  .subsub {
    display: contents;
    flex-wrap: unset;
    height: 107px;
    width: 100% !important;
    }
  .col-6 {
    max-width: 100%;
    -webkit-box-flex: unset;
    flex: unset;
    }
  .header_menu {
    height: 107px;
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    display: flex;
    flex-wrap: unset;
    flex-direction: row;
    }
  .gambar{
    width: 90%;
    height: auto;
    /* margin-right: 49%; */
    }
  .icon{
    display: block; 
    width: 10%;
    }
  .daftar_menu {
    margin-top: 0px !important;
    background: white;
    height: auto;
    }
  .collapse:not(.show) {
    display: block;
    width: 100%;
    background: white;
    }
  .lp_menu {
    display: flex;
      flex-direction: row;
      list-style: none;
    }
  .margin-navbar {
    height: auto;
    margin-right: 0px;
    }
  .bg-navbar {
    width: 90%;
    border: unset !important;
    line-height: 45%;
    }
  .isi_menu {
    border: unset !important;
    width: 33.33%;
    margin-left: 0px !important;
    }
  .isi_menu a {
    margin: 5%;
    height: auto;
    }
    ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
}

</style>
