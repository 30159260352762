<template>
  <div class="allbody">
    <transition name="fade-in-up">
      <router-view />
    </transition>
    <portal-target name="modal" />
    <portal-target name="modal-aktivasi" />
    <portal-target name="modalresult" />
    <portal-target name="buat-sandi" />
    <div class="kepala">
      <Navbar />
    </div>
    <div class="mt-5 pt-5 position-absolute bodi-lp">
      <div style="margin-top: 6%;margin-right: 5%; margin-left: 5%;">
        <div
          class=" custom-card-landing"
          style="z-index:100;height: auto;margin-top: 0;margin-right: 0; margin-left: 0;"
        >
          <img
            src="assets/images/Lingkaran.png"
            class="lingkaran"
            alt="lingkaran"
          />
          <b-container
            fluid
            class="pt-4"
            style="padding: 0 !important;width: auto;"
          >
            <b-row class="pt-5 pl-5 narasi">
              <b-col cols="5" class="pt-3 pl-5 narasi-text">
                <span
                  class="top-title"
                  style="margin-bottom: 1%;font-size: 100%;"
                  >SIORMAS</span
                >
                <h3
                  class="top-subtitle"
                  style="margin-top: -1%;margin-bottom: 7%;line-height: 31px !important;font-size: 51%;"
                >
                  Sarana Aktivitas dan Interaksi Antara ORMAS dengan Pemerintah
                </h3>
                <div
                  :class="
                    notFound ? 'form-landing-err mt-10' : 'form-landing mt-10'
                  "
                  style=" z-index:500;margin-bottom: 0px !important;min-width: 174px;width: 100%;height: auto;margin-top: 0px!important; padding-left: 0px !important"
                  id="tooltip-not-found"
                >
                  <div class="form-row justify-content-md-start pt-3 pencarian">
                    <div class="col-md-9 mb-3 kolom-input">
                      <input
                        type="text"
                        class="form-control border-0"
                        id="inputEmail"
                        placeholder="Pencarian Informasi ORMAS"
                        autocomplete="off"
                        v-model="search"
                        @keyup.enter="searchGood()"
                        style="padding: 0;margin-top: 5.6%;margin-bottom: 5%;height: auto;"
                      />
                    </div>
                    <div class="tombol-cari">
                      <button
                        type="button"
                        class="btn-cari"
                        @click="searchGood"
                        style="background-color: text-replace: auto;"
                      >
                        <span v-if="!loading" class="cari-cari">
                          <i class="flaticon-search" style="width: 30%;"></i>
                          <i style="width: 70%;">Cari</i>
                        </span>
                        <b-spinner medium v-else></b-spinner>
                      </button>
                      <!-- </router-link -->
                    </div>
                  </div>
                </div>
                <b-tooltip
                  :disabled.sync="disabled"
                  :show.sync="show"
                  target="tooltip-not-found"
                  variant="danger"
                  placement="bottomleft"
                >
                  {{
                    modeAlert == 0
                      ? "Tidak boleh kosong, dan minimal 3 karakter"
                      : "Nama Ormas tidak ditemukan"
                  }}
                </b-tooltip>
              </b-col>
              <b-col class="alamat">
                <div class="kolom-alamat">
                  <b-col class="kolom-building">
                    <img
                      src="assets/images/v284_1224.png"
                      class="building "
                      alt="building"
                    />
                  </b-col>
                  <b-col class="alamat-text">
                    <div class="nama-alamat">
                      <span class="alamatnya"
                        >Jl. Medan Merdeka Utara No. 7, Jakarta Pusat
                        10110</span
                      >
                      <b-container class="pt-5 kolom-surat">
                        <div class="surat">
                          <img
                            src="assets/images/v284_1231.png"
                            class="icon-surat"
                            alt="surat"
                          />
                          <span class="text-surat">
                            ormas@kemendagri.go.id
                          </span>
                          <span class="text-surat"> dit.ormas@gmail.com </span>
                        </div>
                      </b-container>
                    </div>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <img
            src="assets/images/Meliuk-liuk.png"
            class="liuk"
            alt="abstract"
            style="width: 100%;height: auto;min-width: 50%;;margin-top: -54px;bottom: unset;position: unset;"
          />
        </div>
      </div>
      <b-container class="manfaat" fluid>
        <b-row class="kartu-manfaat">
          <b-col class="isi-manfaat im-1" cols="3">
            <CardFloat1 />
          </b-col>
          <b-col class="isi-manfaat im-2" cols="3">
            <CardFloat2 />
          </b-col>
          <b-col class="isi-manfaat im-3" cols="3">
            <CardFloat3 />
          </b-col>
          <b-col class="isi-manfaat im-4" cols="3">
            <CardFloat4 />
          </b-col>
        </b-row>
        <b-row>
          <img src="assets/images/Ellipse.png" class="ellipse" alt="Ellipse" />
          <b-col cols="12 d&t">
            <div class="daftarkan">
              Daftarkan! & Terima Manfaat Terdaftar!
            </div>
          </b-col>
        </b-row>
        <div
          style="position: absolute;text-align: center;display: flex;justify-content: center;align-items: center;height: 52px;width: 89.6%;"
        >
          <img src="assets/images/copyright.svg" alt="" /> Direktorat Jenderal
          Politik dan Pemerintahan Umum {{ copyYear }}
        </div>
      </b-container>
    </div>
    <div
      class="width-bg"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    ></div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Navbar from "@/view/layout/Components/Navbar.vue";
import CardFloat1 from "@/view/pages/landing/CardFloat1";
import CardFloat2 from "@/view/pages/landing/CardFloat2";
import CardFloat3 from "@/view/pages/landing/CardFloat3";
import CardFloat4 from "@/view/pages/landing/CardFloat4";
export default {
  name: "Home",
  components: {
    CardFloat1,
    CardFloat2,
    CardFloat3,
    CardFloat4,
    Navbar,
  },
  data() {
    return {
      search: "",
      notFound: false,
      loading: false,
      disabled: false,
      show: false,
      modeAlert: null,
      copyYear: new Date().getFullYear(),
    };
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/images/bg_indonesia.svg";
    },
  },
  mounted() {
    this.disabled = true;
  },
  methods: {
    async searchGood() {
      if (this.search == "" || this.search.length < 3) {
        this.notFound = true;
        this.show = true;
        this.modeAlert = 0;
        return false;
      }
      // let context = this
      this.loading = true;
      this.notFound = false;
      this.show = false;
      this.$store.dispatch("pencarianData", this.search);
      this.loading = false;
      this.$router.push("/result/" + this.search);
      this.modeAlert = null;
      Swal.close();
      // .dispatch("getFounderOrganization", 4)
      // .then((res) => {

      // if (res.data.length == 0) {
      //   this.notFound = true
      //   this.modeAlert = 1
      //   this.show = true
      // } else {
      //   this.$router.push('/result/' + this.search)
      //   this.modeAlert = null
      // }
      // }).catch(async function (err) {
      //   context.loading = false
      //   await Swal.fire({
      //     title: err.message,
      //     text: "",
      //     icon: "warning",
      //     confirmButtonColor: "#063A69",
      //     confirmButtonText: "Tutup",
      //     cancelButtonText: false
      //   }, function () {
      //     Swal.close();
      //   });
      // })
    },
  },
};
</script>
<style scoped>
.btn-cari {
  background: unset !important;
  box-shadow: unset !important;
  width: 100%;
  padding: unset !important;
  display: unset !important;
  max-width: unset !important;
}
.cari-cari {
  display: flex;
  background: #053a69;
  -webkit-box-shadow: 0 4px 15px rgb(5 58 105 / 25%);
  box-shadow: 0 4px 15px rgb(5 58 105 / 25%);
  /* border-radius: 10px; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 7% !important;
  padding-bottom: 7% !important;
  padding-left: 15% !important;
  padding-right: 15% !important;
  font-size: 97% !important;
  flex-direction: row !important;
  align-content: center !important;
  align-items: center !important;
}

.form-landing {
  width: 434px;
  height: 59px;
  left: 102px;
  top: 232px;
  box-sizing: border-box;
  border-radius: 15px;
  margin-bottom: 40px;
  padding-left: 15px;
  background: #ffffff;
}

.form-landing-err {
  width: 434px;
  height: 59px;
  left: 102px;
  top: 232px;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid red;
  margin-bottom: 40px;
  padding-left: 15px;
  background: #ffffff;
}
.kepala {
  margin-right: 5% !important;
  margin-left: 5% !important;
}
.bodi-lp {
  padding-top: 0 !important;
  margin-top: 0 !important;
  width: 100%;
}
.narasi {
  margin-right: 0;
  margin-left: 0;
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.narasi-text {
  padding: 0;
  padding-left: 4.7% !important;
  padding-top: 3.2% !important;
  max-width: 38.5%;
  font-size: 43px;
}
.lingkaran {
  margin-right: 3%;
  margin-top: -1%;
  width: 11%;
  height: auto;
}
.pencarian {
  padding-top: 0 !important;
  margin-right: 0;
  margin-left: 0;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: unset !important;
}
.kolom-input {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0 !important;
  margin-left: 7% !important;
  max-width: 67%;
}
.tombol-cari {
  display: flex;
  margin-top: 0px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0px !important;
  margin-bottom: 0px !important;
  width: 24% !important;
}
.alamat {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 61.5% !important;
  padding-top: 3.2% !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 4.7% !important;
}
.kolom-alamat {
  display: flex !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  flex-direction: row !important;
  flex-wrap: unset !important;
}
.kolom-building {
  padding-right: 0px !important;
  padding-left: 28% !important;
}

.kolom-surat {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 18% !important;
  padding-right: 0px !important;
}
.building {
  width: 82% !important;
  height: auto !important;
}
.alamat-text {
  margin-left: -9%;
  padding-top: 25.45px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.nama-alamat {
  display: flex;
  flex-direction: column;
}
.alamatnya {
  color: #053a69;
  background: white;
  width: 100%;
  height: 70px;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px;
}
.manfaat {
  margin-top: 10%;
  padding-left: 70px;
  padding-right: 70px;
}
.daftarkan {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 59px;
  background: #063a69;
  border-radius: 0px 0px 15px 15px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}
.mt-10 {
  /* margin-top: 2.5rem !important; */
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 798px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 2) {
  .bodi-lp {
  }
  .kepala {
    width: 90% !important;
    height: auto;
  }
  .narasi {
    display: flex;
    flex-wrap: unset;
    flex-direction: column;
  }
  .narasi-text {
    padding-right: 4.7% !important;
    max-width: 100%;
    font-size: 43px;
  }
  .lingkaran {
    margin-right: -1%;
    margin-top: -7%;
    width: 30%;
  }
  .pencarian {
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
  }
  .kolom-input {
    width: 70%;
  }
  .tombol-cari {
    width: 30%;
  }
  .alamat {
    padding-right: 4.7% !important;
    padding-left: 4.7% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }
  .kolom-alamat {
    display: flex !important;
    flex-direction: row-reverse !important;
    flex-wrap: unset !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .building {
    width: 100% !important;
    height: auto !important;
    min-height: 133px;
  }
  .kolom-building {
    -webkit-box-flex: 0;
    flex: unset !important;
    max-width: unset !important;
    width: 30% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .alamat-text {
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    max-width: unset !important;
    width: 70% !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .nama-alamat {
    width: 95%;
    /* height: unset; */
  }
  .alamatnya {
    font-size: 145%;
    margin-top: 5%;
    padding-left: 3% !important;
    padding-right: 3% !important;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
  .surat {
    margin-top: 5% !important;
    max-width: 226px !important;
  }
  .kolom-surat {
    padding: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .width-bg {
    display: none;
  }
  .allbody {
    width: 100% !important;
  }
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .manfaat {
    margin-top: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 5%;
    margin-left: 5%;
    width: 90%;
  }
  .kartu-manfaat {
    display: flex;
    flex-wrap: unset;
    margin-right: 0px;
    margin-left: 0px;
    flex-direction: column;
  }
  .isi-manfaat {
    flex: unset;
    max-width: unset;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .card-floating {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: unset !important;
    height: auto !important;
  }
  .ellipse {
    display: none;
  }
  .d&t {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (min-device-width: 640px) and (min-width: 640px) and (max-device-width: 1239px) and (max-width: 1229px) and (-webkit-min-device-pixel-ratio: 2) {
  .kolom-building {
    padding-right: 0px !important;
    padding-left: 10% !important;
  }

  .building {
    display: none;
  }

  .kolom-surat {
    padding-left: 0px !important;
  }
}
@media screen and (min-device-width: 640px) and (min-width: 640px) and (max-device-width: 798px) and (max-width: 798px) and (-webkit-min-device-pixel-ratio: 2) {
  .building {
    display: block;
  }
}
@media screen and (min-device-width: 641px) and (min-width: 641px) and (max-device-width: 1053px) and (max-width: 1053px) and (-webkit-min-device-pixel-ratio: 2) {
  .manfaat {
    margin-top: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 5%;
    margin-left: 5%;
    width: 90%;
  }

  .kartu-manfaat {
    display: flex;
    flex-wrap: unset;
    margin-right: 0px;
    margin-left: 0px;
    flex-direction: row;
  }

  .isi-manfaat {
    flex: unset;
    max-width: 24% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .im-2 {
    margin-left: 1.33%;
  }
  .im-3 {
    margin-left: 1.33%;
  }
  .im-4 {
    margin-left: 1.33%;
  }
  .card-floating {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
    min-height: 125px !important;
  }
}
</style>
